.services-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
}

.services {
    background-color: #f3f5fa;
    flex-grow: 1;
}

.services-top-section {
    width: 100%;
    height: 80px;
    background: #37517e;
}

.service-box {
    background-color: #ffffff;
    box-shadow: 0px 0 25px 0 rgb(0 0 0 / 10%);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    min-height: 300px;
    margin: 0 25px;
    cursor: pointer;
    transition: all 1s;
    width: 33%;
}

.services-box-container {
    display: flex;
}

.service-box-header {
    color: #37517e;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
}

.service-box-content {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.service-box:hover {
    position: relative;
    bottom: 20px;
    transform: scale(1);
}

.service-box:first-of-type {
    margin-left: 0 !important;
}

.service-box:last-of-type {
    margin-right: 0 !important;
}

@media only screen and (max-width: 600px) {
    .services-top-section {
        height: 66px;
    }

    .services-box-container {
        flex-direction: column;
    }

    .service-box {
        margin: 0 0 24px 0;
        width: 100%;
    }
}