.home {
    width: 100%;
    height: 80vh;
    background: #37517e;
    display: flex;
}

.home-page-wrapper {
    min-height: 100vh;
}

.home-text {
    width: 50%;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-image-wrapper {
    width: 50%;
    display: flex;
}

.home img {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
}

.home-page-content-wrapper {
    display: flex;
    margin: 60px auto;
    max-width: 1140px;
}

.home-page-content-container {
    margin-right: 20px;
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
    width: 33%;
}

.home-page-content-header {
    color: #37517e;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
}

.home-page-content-content {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .home-page-content-wrapper {
        flex-direction: column;
    }
    .home-page-content-container {
        width: auto;
        margin: 12px;
    }

    .home-top-section {
        flex-direction: column-reverse;
        justify-content: center
    }

    .home-text {
        width: 100%;
        text-align: center;
        font-size: 28px;
        line-height: 36px;
    }

    .home-image-wrapper {
        width: 100%;
    }
}
