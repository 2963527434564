#footer {
    font-size: 20px;
    background: #37517e;
    width: 100%;
}

#footer .footer-bottom {
    padding: 30px 0;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .copyright {
        font-size: 14px;
        text-align: center;
        margin: 0 20px;
    }

    #footer .footer-bottom {
        padding: 20px 0;
    }

    .contact-top-section {
        height: 66px;
    }
}