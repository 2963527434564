.breadCrumb {
  padding: 11px 0 0;
  max-width: 1140px;
  margin: auto;
}
.breadCrumb .firstPage {
    font-size: 20px;
    color: #007db8;
    font-weight: unset;
    margin-top: 43px;
    margin-left: 14.2px;
    vertical-align: middle;
    cursor: pointer;
  }

  .breadCrumb .currentPage {
    font-size: 20px;
    color: #cccccc;
    font-weight: unset;
    margin-top: 43px;
    margin-left: 8px;
    vertical-align: middle;
  }

  .breadCrumb .link:visited {
    color: #007db8;
  }
  @media only screen and (max-width: 600px) {
    .breadCrumb .firstPage, .breadCrumb .currentPage {
      font-size: 15px;
    }
  }